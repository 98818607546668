.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: "Roboto", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  background-color: #512689;
  color: #f8f8f8;
  text-align: center;

}

.profile-header__title {
  font-size: 64px;
  line-height: 75px;
  margin: -5px 0 16px 0;
  text-align: center;
}

.profile-header__text {
  font-size: 20px;
  line-height: 23.44px;
  max-width: 864px;
  text-align: center;
  margin: 0 auto 64px auto;
}

@media screen and (max-width: 1300px) {

  .profile-header__text {
    max-width: 700px;
  }
}

@media screen and (max-width: 850px) {

  .profile-header__title {
    font-size: 36px;
    line-height: 42.19px;
    margin-top: 23px;
  }

  .profile-header__text {
    font-size: 16px;
    line-height: 18.75px;
    max-width: 334px;
  }

}