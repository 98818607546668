.card {
  font-family: "Roboto", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 305px;
  border-radius: 10px;
  box-shadow: 0 1px 3.98px rgba(51, 51, 51, 0.15);
}

.card__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 305px;
}

.card__image {
  width: 124px;
  height: 124px;
  margin: 36px 0 16px 0;
}

.card__name {
  font-size: 20px;
  line-height: 23.44px;
  margin: 0 0 24px 0;

}

a, a:hover, a:active, a:focus {
  text-decoration: none;
  color: #151317;
}

.card__like {
  width: 14px;
  height: 12px;
  padding: 8px;
  background: url(../../images/LikeEmpty.svg) no-repeat center;
  border: hidden;
  cursor: pointer;
  margin: 0 20px 20px 0;

}

.card__like:hover {
  opacity: 0.5;
}

.card__like_active {
  background-image: url(../../images/LikeFull.svg);
}

