.header {
  font-family: "Roboto", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 32px 62px 0 62px;
  background-color: #512689;
  align-items: center;
  color: #f8f8f8;
  text-align: center;
  z-index: 1;
}

.header__res {
  font-family: "Roboto", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  padding: 32px 62px 0 62px;
  background-color: #512689;
  align-items: center;
  color: #f8f8f8;
  text-align: center;
  flex-direction: row-reverse;
}

.header__button {
  color: #F8F8F8;
  background-color: #512689;
  border: 1px solid #f8f8f8;
  outline: none;
  font-size: 16px;
  line-height: 22px;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  z-index: 1;
}

.header__button:hover {
  opacity: 0.6;
}


.header__button:disabled {
  opacity: 0.1;
}

.header__button-exit_mobile,
.header__button-return_mobile {
  visibility: hidden;
  width: 0;
  height: 0;
}

@media screen and (max-width: 850px) {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 23px 27px 0 27px;
  }


  .header__button-exit_mobile {
    visibility: visible;
    width: 18px;
    height: 18px;
    background: url(../../images/ExitMobile.svg) no-repeat center;
    border: hidden;
    cursor: pointer;

  }

  .header__button-return_mobile:hover,
  .header__button-exit_mobile:hover {
    opacity: 0.6;
  }

  .header__button-return_mobile {
    visibility: visible;
    width: 18px;
    height: 18px;
    background: url(../../images/ReturnMobile.svg) no-repeat center;
    border: hidden;
    cursor: pointer;

  }

  .header__button {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}


