.sign-up {
  font-family: "Roboto", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  margin-top: 253px;
}

.sign-up__form {
  font-family: "Roboto", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  max-width: 468px;
  width: 100%;
  padding: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  position: relative;
}

.sign-up__title {
  text-align: left;
  font-size: 20px;
  line-height: 23.44px;
}

.sign-up__name-input {
  font-size: 16px;
  line-height: 21.79px;
  text-align: left;
  margin: 16px 0 8px 0;
}

.sign-up__input {
  border: none;
  color: #808185;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16.41px;
  padding: 16px 8px 16px 8px;
  max-width: 452px;
  width: 100%;
  background-color: #f8f8f8;
  margin: 0;
  outline: none;
}

.sign-up__input-invalid {
  outline: 1px solid #FF6161;
}

.sign-up__input-button {
  position: absolute;
  border: none;
  outline: none;
  background-color: #F8F8F8;
  cursor: pointer;
  width: 24px;
  height: 24px;
  top: 333px;
  right: 25px;
}

.sign-up__input-button:hover,
.sign-up__input-button-repeat:hover {
  opacity: 0.5;
}

.sign-up__input-button-repeat {
  position: absolute;
  border: none;
  outline: none;
  background-color: #F8F8F8;
  cursor: pointer;
  width: 24px;
  height: 24px;
  top: 443px;
  right: 25px;
}

.sign-up__error-message {
  padding-top: 4px;
  opacity: 0;
  font-size: 10px;
  line-height: 11.72px;
  font-weight: 400;
  display: block;
  color: #FF6161;
  text-align: left;
}

.sign-up__button {
  color: #F8F8F8;
  background-color: #512689;
  border: 1px solid #f8f8f8;
  outline: none;
  font-size: 16px;
  line-height: 21.79px;
  padding: 13px 0 13px 0;
  width: 100%;
  border-radius: 8px;
  margin-top: 10px;
}

.sign-up__button:hover {
  opacity: 0.6;
}

.sign-up__button:disabled {
  opacity: 0.1;
}

.sign-up__error-message_visible {
  opacity: 1;
}

.sign-up__input-box {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 850px) {
  .sign-up {
    margin-top: 64px;
  }

  .sign-up__form {
    max-width: 343px;
    width: 100%;
    padding: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    position: relative;
  }

  .sign-up__input {
    max-width: 327px;
  }
}