.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  display: flex;
  border-style: none;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s 0.5s, opacity 0.6s;
}

.modal_opened {
  display: flex;
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  transition: visibility 0s, opacity 0.6s;
}

.modal .modal__content {
  margin: 200px auto 0 auto;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 0 0 10px #000;
  position: relative;
}

.modal__close {
  background: transparent url(../../images/Close.svg);
  width: 32px;
  height: 32px;
  position: absolute;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  top: -30px;
  right: -30px;
}

.modal__close:hover {
  opacity: 0.8;
}

.modal__button {
  color: #F8F8F8;
  background-color: #512689;
  border: 1px solid #f8f8f8;
  outline: none;
  font-size: 16px;
  line-height: 22px;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  z-index: 1;
}

.modal__button:hover {
  opacity: 0.5;
}

.modal__title {
  font-size: 26px;
  line-height: 28px;
}

.modal__text {
  font-size: 20px;
  line-height: 23.44px;
  max-width: 864px;
  text-align: center;
  margin: 0 auto 16px auto;
}

@media screen and (max-width: 850px) {
  .modal__content {
    max-width: 200px;
    width: 100%;
  }
}